import React, { useEffect } from 'react';
import styled from 'styled-components';
import { loadScript } from 'src/helpers/loadScript';
import { Button, buttonStyles } from './Button';
const HUBSPOT_ID = 6711345;
/**
 * Load the hubspot CTA embed script if it's not loaded yet and initialize the CTA
 * @param id
 */

async function initializeCTA(id) {
  //@ts-ignore
  const hasCTA = window?.hbspt?.cta;

  if (!hasCTA) {
    await loadScript('https://js.hscta.net/cta/current.js');
  } //@ts-ignore

  window.hbspt.cta.load(HUBSPOT_ID, id);
}

const RawHubspotCTA = React.memo(({ id, alt, className, ...props }) => {
  useEffect(() => {
    initializeCTA(id);
  }, [id]);
  return (
    <span id={`hs-${id}`} className={className}>
      <span className="hs-cta-wrapper" id={`hs-cta-wrapper-${id}`}>
        <span className={`hs-cta-node hs-cta-${id}`} id={`hs-cta-${id}`}>
          <Button {...props} to={`https://cta-redirect.hubspot.com/cta/redirect/6711345/${id}`}>
            {alt}
          </Button>
        </span>
      </span>
    </span>
  );
});
export const HubspotCTA = styled(RawHubspotCTA)`
  & #${({ id }) => `hs-cta-${id}`} .cta_button {
    ${buttonStyles}
  }
`;
