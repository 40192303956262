import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { HubspotCTA } from 'src/components/atoms/HubspotCTA';
import { Card } from 'src/components/atoms/Card';
import { Grid } from 'src/components/molecules/Grid';
import { Feature } from 'src/components/molecules/Feature';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  customData = JSON.parse(customData);
  return (
    <Section {...customData?.flags}>
      <Card centered>
        <SectionHeader centered>
          <h2>{heading}</h2>
        </SectionHeader>

        <Grid spacious>
          {callouts.map((callout, i) => (
            <Feature
              key={i}
              icon={JSON.parse(callout.customData).icon}
              title={callout.heading}
              subtitle={callout.content}
            />
          ))}
        </Grid>
        <MDXProvider components={MDXComponents}>
          {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        </MDXProvider>
        {ctas.map((cta, i) => (
          <center key={i}>
            <h3>{cta.heading}</h3>
            {cta.url ? (
              <a href={cta.url} label={cta.a11yText}>
                {cta.text}
              </a>
            ) : (
              <HubspotCTA id={cta.hubspotId} alt={cta.a11yText} />
            )}
          </center>
        ))}
      </Card>
    </Section>
  );
}
