import React from 'react';
import { Icon } from 'src/components/atoms/Icon';
import styled from 'styled-components';
const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;
`;
const IconContainer = styled.div`
  display: flex;
  width: 5rem;
  height: 5rem;
  border-radius: 1rem;
  background-color: #e9e5ff;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.75rem;
`;
const Title = styled.h3`
  margin-bottom: 0.25rem;
`;
export const Feature = ({ icon, title, subtitle }) => (
  <Container>
    <IconContainer>
      <Icon icon={icon} color="var(--primary-color)" size="2x" />
    </IconContainer>
    <div>
      <Title>{title}</Title>
      <p>{subtitle}</p>
    </div>
  </Container>
);
